import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Swith = styled('a')`
  color: #00e100;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  margin-bottom: 23px;
`

function Swith2V1() {
  const { t } = useTranslation()
  return (
    <Swith
      onClick={() => {
        window.location.href = 'https://v1.mimo.exchange/'
      }}
    >
      {t('v1.Switch')}
    </Swith>
  )
}

export default Swith2V1
